.terms-date {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #665AEF;
}

.terms-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding-bottom: 30px;
  padding-right: 200px;
  padding-left: 100px;
}

.terms-container.mobile {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.nav-container {
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  width: 20%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 10%;
  position: static;
}

.terms-nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  border: none;
  background-color: transparent;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  padding-left: 10px;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.terms-nav-link.true {
  border-left: 5px solid #665AEF;
}

.terms-nav-link.false:hover {
  color: #665AEF;
}

.terms-text-container {
  margin-top: 30px;
  max-width: 80%;
  margin-right: 30px;
  float: right;
}

@media screen and (max-width: 800px) {
  .terms-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .nav-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .terms-nav-link {
    margin-right: 20px;
  }
  .terms-nav-link.true {
    border-bottom: 5px solid #665AEF;
    border-left: none;
  }
  .terms-nav-link.false:hover {
    color: #665AEF;
  }
  .terms-text-container {
    margin-left: 10%;
  }
}

.mobile.terms-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.mobile.nav-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mobile.terms-nav-link {
  margin-right: 20px;
}

.mobile.terms-text-container {
  margin-left: 10%;
}

.terms-h1 {
  margin-top: 20px;
}

.terms-h2 {
  color: #665AEF;
  margin-top: 20px;
  font-weight: 600;
}

.terms-h3 {
  margin-top: 10px;
}

.terms-p {
  margin-top: 10px;
}

.terms-incr-p {
  margin-top: 10px;
}

.terms-h1 {
  counter-reset: section;
}

.terms-h2 {
  counter-reset: subsection;
}

.terms-h2:before {
  counter-increment: section;
  content: counter(section) " ";
}

.terms-incr-p {
  counter-reset: subsubsection;
}

.terms-incr-p:before {
  counter-increment: subsection;
  content: counter(section) "." counter(subsection) " ";
}

.nested-ul li {
  margin-left: 20px;
}

.terms-text ul li {
  margin-top: 10px;
}
