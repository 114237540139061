.support-header {
  margin-top: 32px;
  margin-bottom: 15px;
  text-align: center;
}

.support-title {
  font-size: 42px;
  padding: 15px;
  font-weight: bold;
}

.support-subtitle {
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.video-guide-container {
  margin-top: 30px;
  align-items: center;
  margin-bottom: 60px;
}
.video-guide-container .row {
  justify-content: center;
  display: flex;
}

.image-container {
  width: 200px;
}

.video-thumbnail-support {
  height: 300px;
  width: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  transition: 0.3s;
  cursor: pointer;
}
.video-thumbnail-support:hover {
  transform: scale(1.05);
}

.video-guide-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-guide-container .column {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  padding: 20px;
  height: 300px;
}
.video-guide-container .column.description {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-guide-container .column .description-container {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.video-guide-container .column h2 {
  font-size: 30px;
  font-weight: bold;
  color: #665aef;
}
.video-guide-container .column p {
  font-size: 20px;
}
.video-guide-container .row {
  margin: 0 auto;
  width: 100%;
}

.support-tools-container {
  margin: 60px;
}

.support-icon {
  width: 54px;
  margin: 0 auto;
  margin-top: 10px;
}

.support-box-container {
  padding: 20px;
  background-color: #E0E7FF;
  margin: 20px;
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  padding: 20px;
  transition: 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.support-box-container:hover {
  transform: scale(1.03);
}
.support-box-container .column.support-icon-container {
  width: 25%;
}

.support-description {
  padding-left: 10px;
  width: 70%;
  margin: 0 auto;
}

.support-box-title {
  font-weight: bold;
  color: #665aef;
  font-size: 18px;
}/*# sourceMappingURL=Support.css.map */