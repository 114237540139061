.blog-container {
  background-color: white;
  min-height: 35vw;
  padding-left: 250px;
  padding-right: 250px;
  padding-top: 50px;
}

.blog-container.mobile {
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 991px) {
  .blog-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.blog-header {
  font-size: 38px;
}

.blog-header.mobile {
  font-size: 24px;
}

.blog-subheader {
  margin-top: 50px;
  margin-bottom: 50px;
  /* Clear floats after the columns */
}

.blog-subheader:after {
  content: "";
  display: table;
  clear: both;
}

.blog-subheader .column {
  float: left;
  margin: 0 auto;
  display: table;
  height: 72px;
}

.blog-subheader .column .column-container {
  display: table-cell;
  vertical-align: middle;
}

.blog-subheader .profile-pic-container {
  width: 92px;
}

.blog-subheader .profile-pic-container .profile-pic {
  height: 72px;
  width: 72px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 20% 10%;
     object-position: 20% 10%;
  /* try 20px 10px */
  border-radius: 50%;
}

.blog-subheader .profile-pic-container .profile-pic.mobile {
  height: 50.4px;
  width: 50.4px;
}

.blog-subheader .author-container {
  width: 50%;
  float: left;
  width: 70%;
}

.blog-subheader .author-container:after {
  width: 20%;
  content: "";
  display: table;
  clear: both;
}

.blog-content {
  padding-bottom: 40px;
}

.blog-content p {
  line-height: 1.6;
  font-size: 20px;
  margin-bottom: 20px;
  color: #313131;
}

.blog-content p.blog-footer {
  font-style: italic;
  color: black;
}

.blog-content.mobile p {
  font-size: 16px;
}

.blog-content ol {
  margin: 0px 0;
  padding: 0;
  list-style: decimal inside none;
}

.blog-content ol li {
  margin: 0px;
  margin-bottom: 5px;
  padding: 0px;
  text-indent: -1.2em;
  margin-left: 3.4em;
}

.blog-content .blog-link {
  margin-left: 5px;
}

.video-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.video-crop {
  width: 400px;
  overflow: hidden;
  display: block;
  height: 160px;
  margin-bottom: 0px;
}

.cover-video {
  width: 640px;
  height: 280px;
  /* margin-left: -120px; */
  margin-top: -60px;
}

.cover-video.mobile {
  margin-left: -160px;
}

.cover-video::-webkit-media-controls {
  display: none !important;
}
